import React from 'react';


export const stepPersonalInfo = {
    title:"Ingresá tus datos",
    formAutomatic:[
        {
            id: "full-name",
            label: "Nombre completo",
            input_type: "text",
            placeholder: "Nombre completo",
            columns: 6,
            name: "name",
        },
        {
            id: "email",
            label: "Email",
            input_type: "email",
            placeholder: "Email",
            columns: 6,
            name: "email",
        },
        {
            id: "dni",
            label: "DNI",
            input_type: "text",
            placeholder: "DNI",
            columns: 6,
            name: "identification"
        },
        {
            id: "dob",
            label: "Fecha de nacimiento",
            input_type: "date",
            placeholder: "dd/mm/aaaa",
            columns: 6,
            name: "date_of_birth"
        },
    ],
    phoneModule:[
        {
            id:"phone-type",
            input_type:"select",
            value: 2,
            label:"Teléfono",
            columns:12,
            name: "type",
            options:[
                {
                    id:"mobile",
                    value: 2,
                    label:"Celular",
                    img:"/mobile-icon.svg",
                    selected: true,
                },
                {
                    id:"phone",
                    value: 1,
                    label:"Tel. fijo",
                    img:"/phone-icon.svg"
                },
            ]
        },
        {
            id:"country",
            input_type:"select",
            value:"arg",
            label:"País",
            columns:2,
            columnsMobile: 6,
            name: "country_code",
            options:[
                {
                    id:"arg",
                    
                    flag:"🇦🇷",
                    label:"Argentina",
                    value: 549,
                },
                {
                    id:"chi",
                    
                    flag:"🇨🇱",
                    label:"Chile",
                    value: 569
                },
                {
                    id:"bra",
                    
                    flag:"🇧🇷",
                    label:"Brasil",
                    value: 55
                },
                {
                    id:"mex",
                    
                    flag:"🇲🇽",
                    label:"México",
                    value: 529
                },
            ]
        },
        {
            id:"phone-code",
            input_type:"text",
            label:"Código de área",
            name: "code_area",
            columns: 3,
            columnsMobile:6,
            placeholder: "11",
            pre_input:"0",
            
        },
        {
            id:"phone-number",
            input_type:"text",
            label:"Número",
            columns: 7,
            placeholder: "2345-6789",
            name: "number",

        },
        {
            id:"notes",
            input_type:"text",
            label:<p className="fs-14 text-medium mb-1">Observaciones <span className="text-regular text-grey">(opcional)</span></p>,
            columns: 12,
            placeholder: "Si pertenece a otra persona, horario de atención, etc.",
            name: "note",
        },
    ],
    formManual:[
        {
            id: "full-name",
            label: "Nombre completo",
            input_type: "text",
            placeholder: "Nombre completo",
            columns: 6,
            name: "name",
        },
        {
            id: "email",
            label: "Email",
            input_type: "email",
            placeholder: "Email",
            columns: 6,
            name: "email",
        },
    ],
}


