import React from 'react';


export const stepAddressData = {
    title:"Ingresá tu domicilio",
    form: [
        {
            id:"address",
            label:"Dirección",
            input_type:"text",
            placeholder: "Ingresá tu dirección",
            columns: 12,
            required: true,
        },
        {
            id:"department",
            label:"Piso / Departamento",
            input_type:"text",
            placeholder: "ej. 2 “B”",
            columns: 4,
            required: false,
        },
        {
            id:"city",
            label:"Localidad",
            input_type:"text",
            placeholder: "Localidad",
            columns: 8,
            required: true,
        },
        {
            id:"aditional_info",
            label:<p className="fs-14 text-medium mb-1">Aclaraciones <span className='text-regular text-grey'>(opcional)</span></p>,
            placeholder: "Descripción de la fachada, puntos de referencia, etc.",
            columns: 12,
            required: false,
        },
    ]
}