import React, { useState } from "react";
import { stepPersonalInfo } from "../../data/steps/personalInfo";
import PhoneModule from "../PhoneModule";
import {OrderContext} from "../../context/OrderContext";
import FooterButtonContainer from "../Buttons/FooterButtonContainer";
import {Form} from "react-bootstrap"


const PersonalInfo = ({stepBackwards, stepForward, currentStep}) => {
  const {formValues, handleFormChange, phones, setPhones, step, setStep,} = React.useContext(OrderContext);
  const {title, formAutomatic, formManual} = stepPersonalInfo;

  const [phoneModuleAmount, setPhoneModuleAmount] = useState(1);

  let phoneModules = [];
  
  for (let index = 0; index < phoneModuleAmount; index++) {
    phoneModules.push(
      <PhoneModule 
        key={index}
        phoneModuleAmount={phoneModuleAmount} 
        moduleId={index}
        phoneModules={phoneModules}
        setPhoneModuleAmount={setPhoneModuleAmount}
      />
    );
  }


  const addPhone = () => {
      setPhoneModuleAmount(phoneModuleAmount + 1)
      const newArray = [...phones]
      newArray.push({
        country_code: 549,
        code_area: "",
        number: "",
        type: 2,
        note: "",
      })
      setPhones(newArray);
    }
  const [validated, setValidated] = React.useState(false);

  /* ---------------------- Handler para avanzar de form ---------------------- */
  const handleNextForm = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      stepForward()
      setStep(step + 1)
    }
    event.preventDefault(); setValidated(true);
  }


  return (
    <div className="step-personal-info">
      <h1 className="heading-3 mb-4">{title}</h1>
      <Form className="form-container" noValidate validated={validated} onSubmit={handleNextForm}>
        <div className="row">
          {formValues.type === "AUTOMATIC_ORDER" &&
            formAutomatic.map((input, i) => (
              <div className={`col-12 col-lg-${input.columns} my-3`} key={i}>
                <div className="form-group">
                  <label className="fs-14 mb-1 text-medium" htmlFor={input.id}>
                    {input.label}
                  </label>
                  <Form.Control 
                    id={input.id}
                    placeholder={input.placeholder}
                    type={input.input_type}
                    required
                    className="form-control"
                    name={input.name}
                    onChange={handleFormChange}
                    defaultValue={formValues[input.name]}
                    max={input.input_type === "date" ? "9999-12-31" : ""}
                  />
                </div>
              </div>
            ))}
            {formValues.type === "SELLER_ORDER" &&
              formManual.map((input, i) => (
                <div className={`col-12 col-lg-${input.columns} my-3`} key={i}>
                  <div className="form-group">
                    <label className="fs-14 mb-1 text-medium" htmlFor={input.id}>
                      {input.label}
                    </label>
                    <Form.Control 
                      id={input.id}
                      placeholder={input.placeholder}
                      type={input.input_type}
                      required
                      className="form-control"
                      name={input.name}
                      onChange={handleFormChange}
                      defaultValue={formValues[input.name]}
                    />
                  </div>
                </div>
              ))
            }
        </div>

        {phoneModules}
        
        <div className="add-button">
          <p 
            className="text-cd-primary my-3"
            style={{cursor:"pointer"}}
            onClick={() => addPhone()}
            >
            Agregar teléfono
          </p>
        </div>
        <FooterButtonContainer 
          stepBackwards={stepBackwards} 
          stepForward={stepForward} 
          currentStep={currentStep}
        />
      </Form>
    </div>
  )
}

export default PersonalInfo;