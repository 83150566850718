import React from "react";
import { OrderContext } from "../../context/OrderContext";
import moment from "moment";
import "moment/locale/es";
import FooterButtonContainer from "../Buttons/FooterButtonContainer";
import { useEffect } from "react";


const Confirmation = (props) => {

  const {formValues, phones, step, setStep, route,} = React.useContext(OrderContext);
  const {customPrevHandler, stepBackwards, stepForward, currentStep} = props;
  const [showDeliveryTime, setShowDeliveryTime] = React.useState();
  const [visitLabel, setVisitLabel] = React.useState(false);


  // Set showDeliveryTime and visitLabel
  useEffect(() => {
    
    if (formValues.date_visit === moment(new Date()).format("YYYY-MM-DD")){
      setShowDeliveryTime(false);
      setVisitLabel("Hoy");
    } else if (formValues.date_visit === moment(new Date()).add(1, "days").format("YYYY-MM-DD")){
      setShowDeliveryTime(false);
      setVisitLabel("Mañana");
    }else{
      setShowDeliveryTime(true);
    }

  }, []);



  const buttonHandler = (id) => {
    for (let index = 0; index < id; index++) {
      customPrevHandler();
      setStep(id === 5 ? 1 : 4)
    }
  }



  return (
    <div className="step-confirmation" >
      <h1 className="heading-2 mb-4">
        {
          formValues.type === "AUTOMATIC_ORDER" &&
          "Confirmá tu pedido"
        }
        {
          formValues.type === "SELLER_ORDER" &&
          "Confirmá tus datos"
        }
      </h1>
        <div className="card-container">
          <div className="row">
          <div className="col-12 col-md-6">
            <div className="confirmation-card mb-4">
              <div className="icon-container">
                  <img src="/personal-info-icon.svg" alt="icon" />
              </div>
              <h2 className="fs-20 text-bold text-black text-center mt-2 mb-3">Datos personales</h2>
              <div className="data-container mb-4 mb-lg-0 w-100">
                {formValues.name &&
                  <div className="item-container my-3 d-flex" >
                    <img src="/user-icon.svg" className="me-3" alt="icon" />
                    <p className="text-dark-grey">{formValues.name}</p> 
                  </div>
                }
                {formValues.email &&
                  <div className="item-container my-3 d-flex" >
                    <img src="/email-icon.svg" className="me-3" alt="icon" />
                    <p className="text-dark-grey">{formValues.email}</p> 
                  </div>
                }
                {phones &&
                  <div className="item-container my-3 d-flex" >
                    <img src="/phone-icon.svg" className="me-3" alt="icon" />
                    <p className="text-dark-grey">{phones[0].country_code + phones[0].code_area + phones[0].number}</p> 
                  </div>
                }
                {formValues.identification &&
                  <div className="item-container my-3 d-flex" >
                    <img src="/dni-icon.svg" className="me-3" alt="icon" />
                    <p className="text-dark-grey">{formValues.identification}</p> 
                  </div>
                }
                {formValues.date_of_birth &&
                  <div className="item-container my-3 d-flex" >
                    <img src="/cake-icon.svg" className="me-3" alt="icon" />
                    <p className="text-dark-grey">{moment(formValues.date_of_birth).format('DD/MM/YYYY')}</p> 
                  </div>
                }
              </div>
              <div className="button-container">
                <button
                  className="text-cd-primary"
                  onClick={() => buttonHandler(1)}
                >
                  Editar datos personales
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
              {formValues.type === "AUTOMATIC_ORDER" && (
                <div className="confirmation-card mb-4">
                  <div className="icon-container">
                      <img src="/address-icon.svg" alt="icon" />
                  </div>
                  <h2 className="fs-20 text-bold text-black text-center mt-2 mb-3">Domicilio</h2>
                  <div className="data-container mb-4 mb-lg-0">
                    {formValues.address &&
                      <div className="item-container my-3 d-flex" >
                        <img src="/pin-icon.svg" className="me-3" alt="icon" />
                        <p className="text-dark-grey">{formValues.address}, {formValues.city}</p> 
                      </div>
                    }
                    {formValues.department &&
                      <div className="item-container my-3 d-flex" >
                        <img src="/building-icon.svg" className="me-3" alt="icon" />
                        <p className="text-dark-grey">{formValues?.department}</p> 
                      </div>
                    }
                    {formValues.aditional_info &&
                      <div className="item-container my-3 d-flex" >
                        <img src="/comment-icon.svg" className="me-3" alt="icon" />
                        <p className="text-dark-grey">{formValues?.aditional_info}</p> 
                      </div>
                    }
                  </div>
                  <div className="button-container">
                    <button
                      className="text-cd-primary"
                      onClick={() => buttonHandler(5)}
                    >
                      Editar domicilio
                    </button>
                  </div>
                </div>
              )} 
              {formValues.type === "SELLER_ORDER" &&(
                <div className="confirmation-card mb-4">
                  <div className="icon-container">
                    <img src="/address-icon.svg" alt="icon" />
                  </div>
                  <h2 className="fs-20 text-bold text-black text-center mt-2 mb-3">Domicilio</h2>
                  <div className="data-container mb-4 mb-lg-0">
                    {formValues.address &&
                      <div className="item-container my-3 d-flex" >
                        <img src="/pin-icon.svg" className="me-3" alt="icon" />
                        <p className="text-dark-grey">{formValues.address}, {formValues.city}</p> 
                      </div>
                    }
                    {formValues.department &&
                      <div className="item-container my-3 d-flex" >
                        <img src="/building-icon.svg" className="me-3" alt="icon" />
                        <p className="text-dark-grey">{formValues?.department}</p> 
                      </div>
                    }
                    {formValues.aditional_info &&
                      <div className="item-container my-3 d-flex" >
                        <img src="/comment-icon.svg" className="me-3" alt="icon" />
                        <p className="text-dark-grey">{formValues?.aditional_info}</p> 
                      </div>
                    }
                    <div className="my-2 d-flex align-items-start" >
                      <img src="/phone-icon.svg" className="me-3" alt="icon" />
                      <p className="text-dark-grey">
                        <b className="text-capitalize">
                          {formValues.method_contact === "phone" ? "Teléfono" : 
                            formValues.method_contact === "whatsapp" ? "Whatsapp" :
                            formValues.method_contact === "email" ? "Email" :
                            ""}
                        </b> es el canal de contacto que elegiste para que un agente se comunique con vos.
                      </p> 
                    </div>
                  </div>
                  <div className="button-container">
                    <button
                      className="text-cd-primary"
                      onClick={() => buttonHandler(3)}
                    >
                      Editar domicilio
                    </button>
                  </div>
                </div>
              )}
            
          </div>

          { formValues.type === "AUTOMATIC_ORDER" &&
            <div className="col-12">
              <div className="date-visit-card d-flex flex-column mb-4">
                {formValues.product_type === 2 &&
                  <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center w-100 my-2">
                    <p className="fs-20 text-black text-medium">Contacto dispenser frío-calor</p>
                    <p className="fs-20 text-grey text-capitalize">
                      {formValues.method_contact}
                    </p>
                  </div>
                }
                { formValues.date_visit !== "" &&
                  <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center w-100 my-2">
                    <p className="fs-20 text-black text-medium">
                      { formValues.product_type === 2 ? 
                        "Entrega dispenser natural" :
                        "Fecha y hora de entrega"
                      }
                    </p>
                    <p className="fs-20 text-grey">
                      { visitLabel ?
                        visitLabel :
                        moment(formValues.date_visit,"YYYY-MM-DD").format('D [de] MMMM')
                      } 
                      { showDeliveryTime &&
                        ` de ${route.visit_between_initial} a ${route.visit_between_final}`
                      }
                    </p>
                  </div>
                }
              </div> 
            </div>
          }

        </div>
      </div>
      <FooterButtonContainer 
        stepBackwards={stepBackwards} 
        stepForward={stepForward} 
        currentStep={currentStep}
      />
    </div>
  )
}

export default Confirmation;