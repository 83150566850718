import React from 'react';
import { stepPersonalInfo } from "../data/steps/personalInfo";
import { OrderContext } from "../context/OrderContext";

const PhoneModule = (props) => {


  const {setPhones, phones} = React.useContext(OrderContext)
  const {phoneModule} = stepPersonalInfo;
  const {phoneModuleAmount,  moduleId, phoneModules, setPhoneModuleAmount} = props;


  const handlePhoneChange = (e) => {
    let newArray = [...phones];
    newArray[moduleId][e.target.name] = e.target.value;
    setPhones(newArray);
  }

  const handleDeletePhoneModule = () =>{
    let newArray = [...phones];
    newArray.splice(moduleId, 1);
    setPhones(newArray)
    setPhoneModuleAmount(phoneModuleAmount-1);
    phoneModules.pop();
  }


  return (
    <div className={`row module-phone module-number-${moduleId} position-relative`}>
      {
        phoneModuleAmount > 1 && 
        <button 
            className='text-grey text-medium delete-button'
            onClick={handleDeletePhoneModule}
        >
            Borrar X
        </button>
      }
      {
        phoneModule.map((module,i) => (
          <div 
            className={`col-${module.columnsMobile} col-md-${module.columns} my-3`} 
            key={i}
          >
            <div className="form-group">
              <label className="fs-14 mb-1 text-medium" htmlFor={module.id}>
                  {module.label}
              </label>
              { 
                module.input_type === "select" ?
                <select 
                  id={module.id} 
                  className={`form-control select-${module.id}`}
                  style={{backgroundImage:`url('/chevron-down.svg')`}}
                  name={module.name}
                  onChange={(e) => handlePhoneChange(e)}
                  defaultValue={2}
                  required
                >
                {
                  module.options.map((option,i) => (
                    <option 
                      key={i} 
                      value={option.value} 
                      name={module.name}
                    >
                      {option.flag || option.label}
                    </option>
                  ))
                }
                </select> :
                <div className="d-flex align-items-center">

                  {module.pre_input && <p className="me-2">{module.pre_input}</p>}

                  {module.name === "note" ?
                    <input
                      type={module.input_type}
                      id={module.id}
                      placeholder={module.placeholder}
                      className="form-control"
                      name={module.name}
                      onChange={(e) => handlePhoneChange(e)}
                      defaultValue={phones[moduleId][module.name]}
                      
                    /> : 
                    <input
                      type={module.input_type}
                      id={module.id}
                      placeholder={module.placeholder}
                      className="form-control"
                      name={module.name}
                      onChange={(e) => handlePhoneChange(e)}
                      defaultValue={phones[moduleId][module.name]}
                      required
                    />
                  }
                </div>
              }
            </div>
          </div>
        ))
      }
    </div>
  )
}


export default PhoneModule;