import React, {useState, useEffect} from "react";
import {OrderContext} from '../../context/OrderContext'


const TotalAmountCard = (props) => {
  const {TotalPrice, productsToSend} = React.useContext(OrderContext)
  const {title, showProductsList} = props;
  
  

  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="total-amount-card">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column flex-lg-row align-items-lg-center">
          <h3 className="fs-20 text-bold text-black me-3 mb-0">{title}</h3>
          {showProductsList && 
            <button
              className="fs-14 text-cd-primary text-regular"
              style={{cursor:"pointer"}}
              onClick={() => setShowDetails(!showDetails)}
            >
              {!showDetails ? "Mostrar" : "Ocultar"} detalle
            </button>
          }
        </div>
        <p className="fs-20 text-bold">$ {TotalPrice}</p>
      </div>

      <div 
        className="details-container my-4"
        style={{display:showDetails ? "block" : "none"}}
      >
         {productsToSend.map((product,i) => (
          <div className="detail-item my-2" key={i}>
            <p className="text-grey text-regular">
              <span className="me-3">{product.quantity}</span>
              {product.name}
            </p>
            <p className="text-black">
              {`$${product.price}`}
            </p>
          </div>
        ))} 
      </div>
    </div>
  )
}

export default TotalAmountCard;