import * as React from "react";
import {Form} from 'react-bootstrap'

const Radiobutton = (props) => {

    const {value, name, label, description, img, handleFormChange, formValues, required} = props;

    const checkDefault = (name) => {
        if (name === "method_contact") {
            return formValues.method_contact === value ? true : false
        }
        if (name === "date_visit") {
            return formValues.date_visit === value ? true : false
        }
    }
    
    return (
        <div className="contact-radiobutton position-relative">
            <label htmlFor={value} className="d-flex align-items-start align-items-lg-center">
                {required ? (
                    <Form.Check 
                        id={value}
                        value={value}
                        name={name}
                        type="radio" 
                        className="radiobutton" 
                        onChange={handleFormChange}
                        defaultChecked={checkDefault(name)}
                        required                  
                    />
                ) : ( 
                     <Form.Check 
                        id={value}
                        value={value}
                        name={name}
                        type="radio" 
                        className="radiobutton" 
                        onChange={handleFormChange}
                        defaultChecked={checkDefault(name)}
                    />
                )}
                <div className="d-flex flex-column flex-lg-row">
                    <p className="fs-20 text-black text-medium ms-3 ms-lg-4 me-lg-3">{label}</p>
                    { description && <p className="text-grey text-regular fs-20 ms-3 ms-lg-0">{description}</p>}
                </div>
                {img && <img src={img} alt="icon" />}
            </label>
        </div>
    )
}

export default Radiobutton;
