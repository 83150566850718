
export const stepManualConfirmationData = {
    title:"Confirmá la información",
    cardData:[
        {
            backStepId: 1,
            icon: "/personal-info-icon.svg",
            title: "Datos personales",
            buttonTitle: "Editar datos personales",
            content: [
                {
                    id: "client_name",
                    icon:"/user-icon.svg",
                },
                {
                    id: "client_email",
                    icon:"/email-icon.svg",
                },
                {
                    id: "client_phone",
                    icon:"/phone-icon.svg",
                },
                {
                    id: "client_dni",
                    icon:"/dni-icon.svg",
                },
                {
                    id: "client_dob",
                    icon:"/cake-icon.svg",
                },
            ]
        },
        {
            backStepId: 2,
            icon: "/contact-icon.svg",
            title: "Canal de contacto",
            buttonTitle: "Editar canal de contacto",
            content:[
                {
                    text: "es el canal de contacto que elegiste para que un agente se comunique con vos."
                }
            ]
        }
    ]
}

export const stepAutomaticConfirmationData = {
    title:"Confirmá tu pedido",
    cardData:[
        {
            backStepId: 1,
            icon: "/personal-info-icon.svg",
            title: "Datos personales",
            buttonTitle: "Editar datos personales",
            content: [
                {
                    id: "client_name",
                    icon:"/user-icon.svg",
                },
                {
                    id: "client_email",
                    icon:"/email-icon.svg",
                },
                {
                    id: "client_phone",
                    icon:"/phone-icon.svg",
                },
                {
                    id: "client_dni",
                    icon:"/dni-icon.svg",
                },
                {
                    id: "client_dob",
                    icon:"/cake-icon.svg",
                },
            ]
        },
        {
            backStepId: 5,
            icon: "/address-icon.svg",
            title: "Domicilio",
            buttonTitle: "Editar domicilio",
            content:[
                {
                    id: "client_address",
                    icon:"/pin-icon.svg",
                },
                {
                    id: "client_department",
                    icon:"/building-icon.svg",
                },
                {
                    id: "client_note",
                    icon:"/comment-icon.svg",
                },
            ]
        }
    ]
}