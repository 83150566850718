import React from 'react'
import TotalAmountCard from '../Cards/TotalAmountCard'
import { OrderContext } from '../../context/OrderContext';
import { navigate } from 'gatsby';
import {Spinner} from 'react-bootstrap'

export default function FooterButtonContainer (props) {

  const isBrowser = typeof window !== "undefined"
  const {stepBackwards, currentStep} = props;
  const {step, setStep, handleSubmit, sending} = React.useContext(OrderContext)
  return (
    <div className="btn-container">
      { currentStep === 5 &&
       isBrowser && sessionStorage.getItem('route_available') === "true" && (
         <div className="w-100 mb-4">
            <TotalAmountCard
              title="Total del pedido"
              showProductsList={true}
            />
          </div>         
        )
       
      }
      {
        isBrowser && sessionStorage.getItem('order_type') === "AUTOMATIC_ORDER" &&
        currentStep === 2 &&
        isBrowser && sessionStorage.getItem('route_available') === "true" && (
        <div className="w-100 mb-4">
          <TotalAmountCard
            title="Total"
            showProductsList={false}
          />
        </div>
        )
      }
      <div className="d-flex w-100 flex-column flex-md-row justify-content-md-between">

        <button 
          className="btn-white" 
          onClick={() => stepBackwards() & setStep(step - 1)}
          style={{visibility:currentStep === 0 ? "hidden" : "visible"}}
        >
          Anterior
        </button>

        {isBrowser && window.sessionStorage.getItem('route_available') === "true" ? (
          <>
            { isBrowser && window.sessionStorage.getItem('order_type') == "AUTOMATIC_ORDER" ? (
              currentStep === 5  ?
              <button 
                className="btn-cd-primary" 
                onClick={() => handleSubmit()}
                disabled={sending}
              > 
                {sending ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span>Enviando...</span>
                  </>
                
                ) : "Confirmar"}
              </button> :
              <button 
                type="submit"
                className="btn-cd-primary" 
                
               
              >
                Siguiente
              </button>
            ) : (
              currentStep === 3  ?
              <button 
                className="btn-cd-primary" 
                onClick={() => handleSubmit()}
                disabled={sending}
              >
                 {sending ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span>Enviando...</span>
                  </>
                
                ) : "Confirmar"}
              </button> :
              <button 
                className="btn-cd-primary" 
                
                type="submit"
              >
                Siguiente
              </button>
            ) 

          }
        </>
        ) : (
          <>
            { isBrowser && window.sessionStorage.getItem('order_type') == "AUTOMATIC_ORDER" ? (
              currentStep === 2  ?
              <button 
                className="btn-cd-primary" 
                  onClick={() => navigate('/')}
              >
                Ir al home
              </button> :
              <button 
                className="btn-cd-primary" 
                
                type="submit"
              >
                Siguiente
              </button>
            ) : (
              currentStep === 2  ?
              <button 
                className="btn-cd-primary" 
                onClick={() => navigate('/')}
              >
                Ir al home
              </button> :
              <button 
                className="btn-cd-primary" 
                type="submit"
                
              >
                Siguiente
              </button>
            ) 

          }
        </>
        ) }
        
       
      </div>
    </div>
  )
}
