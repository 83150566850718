import React from "react";
import {stepAddressData} from '../../data/steps/address';
import {Form, Col} from 'react-bootstrap'
import Autocomplete from 'react-google-autocomplete';
import FooterButtonContainer from "../Buttons/FooterButtonContainer";
import {OrderContext} from '../../context/OrderContext';




const Address = ({stepBackwards, stepForward, currentStep, orderType}) => {
  const addressRef = React.useRef()
  const {handleFormChange, setFormValues, formValues, step, setStep,} = React.useContext(OrderContext)
  const {title, form} = stepAddressData;
  const [validated, setValidated] = React.useState(false);
  const [message, setMessage] = React.useState("")
  let address_ok = false;
  let no_address_defined = false;


  /* ---------------------- Handler para avanzar de form ---------------------- */
  const handleNextForm = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (form.checkValidity() === true) {
      stepForward()
      setStep(step + 1)
    }
    event.preventDefault(); setValidated(true);
  }

  const validatorAddress = (event) => {
    if (address_ok === false) {
      let  message = no_address_defined ? "Ubicación sin dirección definida." : "Debe seleccionar una dirección de la lista."
      addressRef.current.setCustomValidity(message)
      setMessage(<p className="mt-2 text-invalid">{message}</p>)
    }
    if (address_ok === true) {
      addressRef.current.setCustomValidity("")
      addressRef.current.reportValidity()
      addressRef.current.required = true
      setMessage("")
    }  
    if(addressRef.current.value === "") {
      addressRef.current.required = true
    }
  }

  React.useEffect(() => {
    if(orderType){
      setFormValues({type: orderType})

    }
  },[orderType])

  return (
    <div className="step-address">
      <h1 className="heading-3 mb-4">{title}</h1>
      <Form className="form-container" noValidate validated={validated} onSubmit={handleNextForm}>
        <div className="row">
            {form.map((input, i) => {
              return (
                <Col xs={12} lg={input.columns} className='my-3' key={i}>
                <Form.Group>
                  <Form.Label className='fs-14 mb-1 text-medium'>{input.label}</Form.Label>
                  {input.input_type ? (
                    input.id === "address" ? (
                      <>
                      <Autocomplete
                        className="form-control"
                        name={input.id}
                        required
                        defaultValue={formValues.address}
                        placeholder={input.placeholder} 
                        apiKey="AIzaSyBcdmoRvRQVuHXOhjxz-OpLKTrxtKnj8aI" // acá va la API key que es :
                        onFocus={(event) => validatorAddress(event)}
                        onBlur={(event) =>  validatorAddress(event)}
                        onChange={(event) => validatorAddress(event)}
                        ref={addressRef}
                        options={{
                            types: ["geocode", "establishment"],
                            componentRestrictions: { country: "ar" }
                        }}
                        onPlaceSelected={(place, autocomplete) => {
                            let address_name
                            let address_number
                            for (let index = 0; index < place.address_components.length; index++) {
                              for (let i = 0; i < place.address_components[index].types.length; i++) {
                                switch(place.address_components[index].types[i]){
                                  case "route" :
                                    address_name = place.address_components[index].long_name;
                                  break;
                                  case "street_number" :
                                    address_number = place.address_components[index].long_name;
                                  break;
                                }
                              }
                            }
                            if(address_name && address_number){
                              address_ok = true
                              setFormValues({address: `${address_name} ${address_number}`})
                              setFormValues({lat: place.geometry.location.lat()})
                              setFormValues({lng: place.geometry.location.lng()})
                              addressRef.current.required = false
                            }else{
                              no_address_defined = true 
                              address_ok = false
                            }
                            validatorAddress()
                        }}
                      />
                      {message !== "" && message}
                      </>
                    ) : (
                      input.required ?
                        <Form.Control
                          required name={input.id}
                          type={input.input_type}
                          placeholder={input.placeholder}
                          onChange={handleFormChange}
                          defaultValue={formValues[input.id]}
                        />
                        :
                        <Form.Control
                          name={input.id}
                          type={input.input_type}
                          placeholder={input.placeholder}
                          onChange={handleFormChange}
                          defaultValue={formValues[input.id]}
                        />
                    )
                  ) : (
                    input.required ?
                      <Form.Control
                        name={input.id}
                        as="textarea"
                        rows="4"
                        placeholder={input.placeholder}
                        onChange={handleFormChange}
                        defaultValue={formValues[input.id]}
                      />
                      :
                      <Form.Control
                        name={input.id}
                        as="textarea"
                        rows="4"
                        placeholder={input.placeholder}
                        onChange={handleFormChange}
                        defaultValue={formValues[input.id]}
                      />

                  )}
                </Form.Group>
              </Col>
              )
            }
            )}
        </div>
        <FooterButtonContainer
          stepBackwards={stepBackwards}
          stepForward={stepForward}
          currentStep={currentStep}
        />
      </Form>
    </div>
  )
}

export default Address;