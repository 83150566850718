import axios from "axios"
import React from "react"
import {Link} from "gatsby"
import { OrderContext } from "../../context/OrderContext"

export default function RouteInvalid ( ) { 
  const {formValues, tag, route, phones, recive_drum, products, TotalPrice} = React.useContext(OrderContext)
  const isBrowser = typeof window !== "undefined"
  const body = {
      type:  route.server_available === true ? "NOT_AVAILABLE" : "SERVER_NOT_AVAILABLE",
      product_type: formValues.product_type,
      tag: (isBrowser && window.sessionStorage.getItem('utm')!== "undefined") ? window.sessionStorage.getItem('utm') : "google",
      address: {
        address: formValues.address,
        flat_number: formValues.department,
        location: formValues.city,
        note: formValues.aditional_info,
        lat: formValues.lat,
        lng: formValues.lng,
        route:  route.code_router !== "" ? route.code_router : null,
      },
      client: {
        name: formValues.name,
        email: formValues.email,
        identification: formValues.identification ? formValues.identification: null ,
        date_of_birth: formValues.date_of_birth ? formValues.date_of_birth : null ,
        phones: phones
      },
      visit: {
        method_contact: formValues.method_contact,
        recive_drum: recive_drum,
        date_visit: formValues.date_visit,
        time_visit: route.visit_between_initial !== null ? `${route.visit_between_initial} a ${route.visit_between_final}` : "",
      },
      total: TotalPrice,
      products: products.filter((prod) => prod.quantity !== 0)
    }
  React.useEffect(() => { 
    
    axios.post(`https://api-landing.rosmino.com.ar/wcp/route-not-available`, body).then((res) => {
      
    }).catch((err) => console.error(err))
  }, [])
  return ( 
    <div className="step-route-invalid">
      <div className="form-container text-center pb-5">
        <div className="d-flex flex-column flex-lg-row align-items-center mb-5">
          <div className="d-flex flex-column justify-content-center order-2 order-lg-1 my-4 my-lg-0 pe-lg-5">
            <h1 className="fs-20 text-center text-lg-start text-bold mb-4">¡Ups! Lamentablemente no llegamos a tu domicilio</h1>
            <p className="text-grey text-center text-lg-start text-regular">Tu domicilio no se encuentra dentro de nuestra zona de entrega.</p>
          </div>
          <img src="/invalid-route-icon.svg" className="order-1 order-lg-2" alt="icon" />
        </div>
        <Link to="/" className="btn-cd-primary mt-5">Volver al inicio</Link>
      </div>
    </div>
  )
}